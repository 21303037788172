import { Button, Col, DatePicker, Form, Image, Input, InputNumber, Row, Select, Spin, Switch, Table } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MinusOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';
import Search from 'antd/es/input/Search';
const { RangePicker } = DatePicker;

function FormFlashSale() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(250);
    const [dataAll, setDataAll] = useState([]);
    const [data, setData] = useState([]);
    const [pageInfo, setPageInfo] = useState('');
    //product
    const [product, setProduct] = useState([]);
    const [variant, setVariant] = useState([]);
    const [productValue, setProductValue] = useState();
    const [variantValue, setVariantValue] = useState();
    const [time, setTime] = useState([]);
    const [arrInputDate, setArrInputDate] = useState([]);
    const [arrInputTime, setArrInputTime] = useState([]);
    const [page, setPage] = useState(1);

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            width: 60,
            sorter: false,
            render: (text, record, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Gambar',
            dataIndex: 'Images',
            key: "Images",
            sorter: false,
            width: 100,
            render: (text, record) => {
                return (
                    <Image
                        width={80}
                        height={80}
                        src={record.Images}
                    />
                );
            }
        },
        {
            title: 'Nama Produk',
            dataIndex: 'ProductName',
            key: "ProductName",
            width: 150,
            sorter: false,
            render: (text, record) => {
                return (<p>{record.ProductName + ' - ' + record.DescriptionVariant}</p>);
            }
        },
        {
            title: 'Harga Normal',
            dataIndex: 'Price',
            key: "Price",
            width: 100,
            sorter: false,
            render: (text, record) => {
                return (<p>{currencyFormat(Number(record.Price))}</p>);
            }
        },
        {
            title: 'Harga Akhir',
            dataIndex: 'DiscountEndPrice',
            key: "DiscountEndPrice",
            sorter: false,
            width: 200,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Rp."
                        style={{
                            width: "90%",
                        }}
                        key={(page - 1) * 10 + index}
                        onChange={(ev) => onInputTable(ev, index, 'DiscountEndPrice')}
                        placeholder='Harga Akhir'
                        defaultValue={record.DiscountEndPrice}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                );
            }
        },
        {
            title: 'Kuota Sale',
            dataIndex: 'DiscountKuota',
            key: "DiscountKuota",
            sorter: false,
            width: 120,
            render: (text, record, index) => {
                return (
                    <InputNumber
                        addonBefore="Qty"
                        style={{
                            width: "90%",
                        }}
                        defaultValue={Number(record.DiscountKuota)}
                        onChange={(ev) => onInputTable(ev, index, 'DiscountKuota')}
                        placeholder='Kuota Sale'
                    />
                );
            }
        },
        {
            title: 'Status',
            dataIndex: 'IsActive',
            key: "IsActive",
            sorter: false,
            width: 80,
            render: (text, record, index) => {
                return (
                    <Switch size="small" defaultChecked={record.IsActive} onChange={(ev) => onInputTable(ev, index, 'IsActive')} />
                );
            }
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: "Action",
            width: 70,
            sorter: false,
            render: (text, record, index) => {
                return (
                    <Button type="text" onClick={() => deleteRow(index)}>
                        <CloseOutlined style={{ color: "red" }} size={25} />
                    </Button>
                );
            }
        },
    ];

    const deleteRow = (index) => {
        var row = [...data];
        row.splice(index, 1);
        setData(row);
        setDataAll(row);
    }

    const save = (ev) => {
        const state = location.state;
        var method = 'POST';
        var arr_times = [];
        var arr_dates = [];
        for (let i = 0; i < arrInputDate.length; i++) {
            arr_dates.push(arrInputDate[i].value);
        }
        for (let i = 0; i < arrInputTime.length; i++) {
            arr_times.push({
                Start: arrInputTime[i].value[0],
                End: arrInputTime[i].value[1]
            });
        }
        var arr_product_fs = [];
        for (let i = 0; i < data.length; i++) {
            var item = data[i];
            var arr_time = [];
            if (item.FlashSaleId !== undefined) {
                for (let i = 0; i < item.FlashTime.length; i++) {
                    arr_time.push(item.FlashTime[i].PeriodFSId);
                }
            }
            var arr_fs = {
                //product
                MiniDesc: item.MiniDesc,
                ProductName: item.ProductName,
                Description: item.Description,
                Images: item.Images,
                ProductId: item.ProductId,
                handle:item.handle,

                //variant
                Price: item.Price,
                BeratProduk: item.BeratProduk,
                DescriptionVariant: item.DescriptionVariant,
                VariantId: item.VariantId,

                //price
                DiscountEndPrice: item.DiscountEndPrice,
                DiscountKuota: item.DiscountKuota,
                DiscountLabel: item.DiscountLabel,
                IsActive: item.IsActive,
                FlashTime: arr_times,
                FlashDate: arr_dates,
            }

            if (item.FlashSaleId !== undefined) {
                //flashsale
                arr_fs.FlashSaleId = item.FlashSaleId;
                arr_fs.FlashTimeDel = arr_time;
            }
            arr_product_fs.push(arr_fs);
        }
        var data_fs = {
            Title: ev.Title,
            ProductFlashsale: arr_product_fs
        }

        //params
        if (state != null) {
            data_fs.DataFlashSaleId = state.item.DataFlashSaleId;
            method = "PUT";
        } else {
            method = "POST";
        }
        postApi(method, data_fs);
    }

    const postApi = (method, values) => {
        const dataReq = {
            method: method,
            url: '/flashsale',
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                navigate(-1);
            } else {
                modalError(res.message);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    useEffect(() => {
        getApi('GET', '/product?limit=250', 'product');
        if (location.state != null) {
            setArrInputDate([]);
            setArrInputTime([]);
            const data = location.state.item;
            var row_date = [];
            var row_time = [];
            var row_data = [];
            form.setFieldValue('Title', data.Title);
            for (let i = 0; i < data.FlashSales.length; i++) {
                const item = data.FlashSales[i];
                var json_data = {
                    FlashSaleId: item.FlashSaleId,
                    FlashTime: item.FlashSalePeriodTimes,
                    id: Number(item.ProductId) + Number(item.VariantId),
                    ProductId: Number(item.ProductId),
                    VariantId: Number(item.VariantId),
                    BeratProduk: item.BeratProduk,
                    Images: item.Images,
                    Videos: item.Videos,
                    ProductName: item.ProductName,
                    DescriptionVariant: item.DescriptionVariant,
                    MiniDesc: item.MiniDesc,
                    Description: item.Description,
                    Price: item.Price,
                    DiscountPercent: item.DiscountPercent,
                    DiscountEndPrice: item.DiscountEndPrice,
                    DiscountKuota: item.DiscountKuota,
                    DiscountAmount: item.DiscountAmount,
                    DiscountLabel: item.DiscountLabel,
                    IsActive: item.IsActive,
                }
                row_data.push(json_data);
            }
            const item_fs = data.FlashSales[0];
            for (let i2 = 0; i2 < item_fs.FlashSalePeriods.length; i2++) {
                row_date.push({
                    label: dayjs(item_fs.FlashSalePeriods[i2].FlashSaleDate),
                    value: item_fs.FlashSalePeriods[i2].FlashSaleDate
                })
            }
            for (let i2 = 0; i2 < item_fs.FlashSalePeriodTimes.length; i2++) {
                var times = item_fs.FlashSalePeriodTimes[i2].MS_PeriodF;
                if (times.Start !== null && times.End !== null) {
                    row_time.push({
                        label: [dayjs(times.Start, 'HH:mm:ss'), dayjs(times.End, 'HH:mm:ss')],
                        value: [times.Start, times.End],
                    })
                }
            }
            setArrInputDate(row_date);
            if (row_time.length > 0) {
                setArrInputTime(row_time);
            } else {
                setArrInputTime([{ label: '', value: '' }]);
            }
            setDataAll(row_data);
            setData(row_data);
        } else {
            setArrInputDate([{ label: '', value: '' }]);
            setArrInputTime([{ label: '', value: '' }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageInfo]);

    const getApi = async (method, url, type) => {
        var query = ''
        if (pageInfo !== '' && type == 'product') {
            query = '&page_info=' + pageInfo
        }
        const dataReq = {
            method: method,
            url: url + query,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'product':
                        var item = res.data.products;
                        setCount(count + 250);
                        var row = [];
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: JSON.stringify(item[i]),
                                label: item[i].title,
                            });
                        }
                        let setDatas = [].concat(product, row);
                        setProduct(setDatas);
                        if (res.count >= count) {
                            setPageInfo(res.page_info);
                        } else {
                            setLoading(false);
                        }
                        break;
                    case 'time':
                        var item_time = res.data;
                        var row = [];
                        for (let i = 0; i < item_time.length; i++) {
                            row.push({
                                value: item_time[i].PeriodFSId,
                                label: item_time[i].Start + ' - ' + item_time[i].End,
                            });
                        }
                        setTime(row);
                        break;
                    case 'product_by_id':
                        const data = location.state.item;
                        var item_product = res.data.product;
                        var item_variant;
                        var row = [];
                        for (let i = 0; i < item_product.variants.length; i++) {
                            row.push({
                                value: JSON.stringify(item_product.variants[i]),
                                label: item_product.variants[i].title,
                            });
                            if (item_product.variants[i].id === Number(data.VariantId)) {
                                item_variant = item_product.variants[i];
                            }
                        }
                        setVariant(row);
                        form.setFieldsValue({
                            ProductId: JSON.stringify(item_product),
                            VariantId: JSON.stringify(item_variant),
                        })
                        break;
                }
            } else {
                console.log(res.message);
            }
        }).catch((e) => {
            console.log(e.message);
        })
    }

    const selectData = (event, type) => {
        var json_arr = JSON.parse(event);
        var row = [];
        if (type === 'product') {
            setProductValue(JSON.stringify(json_arr));
            form.setFieldsValue({
                VariantId: null,
                Price: null,
            })
            for (let i = 0; i < json_arr.variants.length; i++) {
                row.push({
                    value: JSON.stringify(json_arr.variants[i]),
                    label: json_arr.variants[i].title,
                });
            }
            setVariantValue(null);
            setVariant(row);
            setVariantValue(JSON.stringify(json_arr.variants[0]));
        } else {
            setVariantValue(JSON.stringify(json_arr));
        }
    }

    const onInputTable = (ev, index, type) => {
        var i = (page - 1) * 10 + index;
        data[i][type] = ev;
        setDataAll(data);
        setData(data);
    }

    const updateArr = (arr, key, value, valueString, type) => {
        arr[key].value = valueString;
        arr[key].label = value;
    }

    const saveProduct = () => {
        if (productValue !== null) {
            var prod = JSON.parse(productValue);
            var vari = JSON.parse(variantValue);
            var status_duplicate = false;
            for (let i = 0; i < data.length; i++) {
                if (data[i].ProductId === prod.id && data[i].VariantId === vari.id) {
                    status_duplicate = true;
                }
            }

            var imageSrc = prod.image.src;
            if (status_duplicate === false) {
                var arr_prod = {
                    id: prod.id + vari.id,
                    ProductId: prod.id,
                    VariantId: vari.id,
                    BeratProduk: vari.weight,
                    Images: imageSrc,
                    Videos: null,
                    ProductName: prod.title,
                    DescriptionVariant: vari.title,
                    MiniDesc: prod.body_html,
                    Description: prod.body_html,
                    Price: vari.compare_at_price !== null ? Number(vari.compare_at_price) : Number(vari.price),
                    DiscountPercent: 0,
                    DiscountEndPrice: 0,
                    DiscountKuota: 0,
                    DiscountAmount: 0,
                    DiscountLabel: null,
                    handle:prod.handle,
                    IsActive: prod.status === 'active' ? true : false,
                    product: prod,
                    variant: vari,
                }
                var item = arr_prod;
                let setDatas = [].concat(data, item);
                setDataAll(setDatas);
                setData(setDatas);
                setProductValue(null);
                setVariantValue(null);
            } else {
                modalError('Produk ini sudah ditambah');
            }
        } else {
            modalError('Produk Kosong');
        }
    }

    const searchData = (value) => {
        console.log(data)
        var text = value;
        if (text === null || text === '') {
            setData(dataAll)
        } else {
            const arr = [];
            dataAll.find(item => {
                const op = new RegExp(".*" + text + ".*", 'ig');
                const hsl = item.ProductName.match(op);
                if (hsl != null) {
                    arr.push(item)
                }
            }
            );
            setData(arr)
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={form.submit} size='large' >
                    <b style={{ marginLeft: 5 }}>Simpan</b>
                </Button>
            </div>
            <Form form={form} name="form_product" layout="vertical" onFinish={save}>
                <div style={{ marginBottom: 10 }}>
                    <Form.Item
                        name="Title"
                        label="Judul"
                        rules={[
                            {
                                required: true,
                                message: 'Judul kosong!'
                            },
                        ]}

                    >
                        <Input />
                    </Form.Item>
                    <p>Pilih Produk</p>
                    <Select
                        showSearch
                        placeholder="Pilih Produk"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        onChange={(ev) => selectData(ev, 'product')}
                        options={product}
                        value={productValue}
                        style={{ marginRight: 10, width: 300 }}
                    />
                    {/* variant !== null &&
                        <Select
                            showSearch
                            placeholder="Pilih Varian"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onChange={(ev) => selectData(ev, 'variant')}
                            options={variant}
                            value={variantValue}
                            style={{ marginRight: 10, width: 300 }}
                        /> */}
                    <Button
                        onClick={() => saveProduct()}
                        type="primary">
                        Tambah Produk <PlusOutlined />
                    </Button>
                </div>
                <hr />
                <Search
                placeholder="Pencarian"
                allowClear
                enterButton="Search"
                size="medium"
                style={{
                    width:300
                }}
                onChange={(e) => searchData(e.target.value)}
            />
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    scroll={{ y: 300 }}
                    pagination={false}
                    // pagination={{
                    //     onChange(current) {
                    //         setPage(current);
                    //     }
                    // }}
                />

                <div>
                    <Row>
                        <Col>
                            {
                                arrInputDate.map((key, i) => (
                                    <Form.Item
                                        key={i}
                                        label={"Tanggal FlashSale " + (i + 1).toString()}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Tanggal FlashSale kosong!'
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder='Pilih Tanggal'
                                            style={{ width: 300 }}
                                            picker='date'
                                            onChange={(date, dateString) => updateArr(arrInputDate, i, date, dateString, 'date')}
                                            defaultValue={key.label}
                                        />
                                    </Form.Item>
                                ))
                            }
                        </Col>
                        <Col>
                            <Button onClick={() => setArrInputDate([...arrInputDate, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                            <Button onClick={() => setArrInputDate(arrInputDate.splice(0, arrInputDate.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                arrInputTime.map((key, i) => (
                                    <Form.Item
                                        key={i}
                                        label={"Jam FlashSale " + (i + 1).toString()}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Jam FlashSale kosong!'
                                            },
                                        ]}
                                    >
                                        <RangePicker
                                            defaultValue={key.label}
                                            style={{ width: 300 }}
                                            showTime={{
                                                format: 'HH:mm:ss',
                                            }}
                                            format="HH:mm:ss"
                                            picker='time'
                                            onChange={(date, dateString) => updateArr(arrInputTime, i, date, dateString, 'time')}
                                        />
                                    </Form.Item>
                                ))
                            }
                        </Col>
                        <Col>
                            <Button onClick={() => setArrInputTime([...arrInputTime, { label: '', value: '' }])} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><PlusOutlined /></Button>
                            <Button onClick={() => setArrInputTime(arrInputTime.splice(0, arrInputTime.length - 1))} style={{ marginLeft: 10, marginTop: 30 }} type="primary"><MinusOutlined /></Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}

export default FormFlashSale;